export const services = [
    {
        "title": "Exames de Imagem",
        "price": 50,
        "link": "agendamentos/Exames de Imagem",
    },
    {
        "title": "Exames de Laboratório",
        "price": 5,
        "link": "agendamentos/Exames Laboratoriais",
    },
    {
        "title": "Consultas Médicas",
        "price": 90,
        "link": "agendamentos/Especialidades",
    },
    {
        "title": "Exames Cardiológicos",
        "price": 40,
        "link": "agendamentos/Exames Cardiológicos",
    },
    {
        "title": "Exames Oftalmológicos",
        "price": 50,
        "link": "agendamentos/Exames Oftalmológicos",
    },
    {
        "title": "Cirurgias",
        "price": 0,
        "link": "agendamentos/Procedimentos",
    },
]