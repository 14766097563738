import { Link } from 'react-router-dom';

export function Sitemap() {


  return (
    <div>
      <Link to='https://backendsitemaster33doctor.onrender.com/xml'>Conteúdo do Sitemap XML</Link>
    </div>
  );
}

 