import { Link } from "react-router-dom"

// MUI Icons
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Styles
import './styles.css';

export const AboutUs = () => {
    return (
        <div className="aboutus-container">
            <div className="info-about">
                <h1>Sobre nós</h1>
                <div className="crumb">
                    <Link to="/">Ínicio</Link>
                    <ChevronRightIcon />
                    <Link to="/about" id="active">Sobre nós</Link>
                </div>
            </div>

            <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s <br /><br /> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. <br /><br />It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>
            
        </div>
    )
}