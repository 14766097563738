import { useEffect , useState} from 'react';
import { useParams } from 'react-router-dom';
import './styles.css'
import { Filter } from './Filter';
import bannerImg from '../../assets/shape.png';
import { Head } from '../Utils/Head';
import { api } from '../../service/api';
import { city } from '../Utils/city';

interface Data {
  type: string;
  description: string;
  description2: string;
  description3: string;
  title: string;
  meta_tag_description: string;
  url: string;
  image_url: string;
}

interface Props {
  documentos: Data[];
}

export const ChangedPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<Props[]>([])

  const stringSHifens = id?.replace(/-/g, ' ')

  useEffect( () => {
    async function loadData() {
      try {
        const response = await api.get(`categorias/exames/${stringSHifens}`);
        
        setData(response.data) 
      } catch (error) {
        throw error;
      }
    }

    loadData()
    
  },[stringSHifens])

/*   const filteredData = id && data.map((dat) => dat.type.toLowerCase().includes(id.toLocaleLowerCase())) */



  return (
    <>
    <Head title='Agendamentos'/>
      <div className="changed-page--container">
        <div className="banner">
          <img src={bannerImg} alt="banner"/>
          <h1>{stringSHifens} em {city}</h1>
          <p>Agende seu exame com até <span>60% de desconto</span> na rede particular</p>
        </div>

        <Filter filteredData={data} />
      </div>
    </>
  )
}