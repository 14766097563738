import './styles.css'
import logoImg from '../../assets/logo-white.png';
import { Link } from 'react-router-dom';
import { address, telFixo, telWhatsapp } from '../Utils/address';

export const Footer = () => {
  return (
    <footer>
      <div className="bottom-side">
        <div className="logo">
          <img src={logoImg} alt="logo" />

          <h1>{address}</h1>
          <h1 id="tel"><span>Tel Fixo:</span> {telFixo}</h1>
          <h1><span>Whatsapp:</span> {telWhatsapp}</h1>

          <p>© Todos os direitos reservados 33Doctor | 2023</p>
        </div>

        <div className="exames-buscados">
          <ul>
            <h1>Exames mais buscados</h1>
            <li>
              <Link to="/agendamentos/Exames Laboratoriais">Laboratoriais</Link>
            </li>
            <li>
              <Link to="/agendamentos/Exames de Imagem">Imagem</Link>
            </li>
            <li>
              <Link to="/agendamentos/Exames Cardiológicos">Cardiológico</Link>
            </li>
            <li>
              <Link to="/agendamentos/Exames Oftalmológicos">Oftalmológico</Link>
            </li>
            <h3>
              <Link to="/agendamentos/Procedimentos">Ver todos</Link>
            </h3>
          </ul>
        </div>

        <div className="consultas-buscadas">
          <ul>
            <h1>Consultas mais buscadas</h1>
            <li>
              <Link to="/agendamentos/Exames Cardiológicos" key="1">
                Cardiológico  
              </Link>
            </li>
            <li>
              <Link to="/agendamentos/Exames Oftalmológicos" key="2">
                Oftalmológico  
              </Link>
            </li>
            <h3>
              <Link to="/agendamentos/Especialidades" key="3">
                Ver todos
              </Link>
            </h3>
          </ul>
        </div>

        <div className="saiba-mais">
          <ul>
            <h1>Saiba mais</h1>
            <li>
              <a target='_blank' href="https://franquias33doctor.com.br">
                Seja um franqueado
              </a>
            </li>
            <li>
              <a href="/#home">Inicio</a>
            </li>
            <li>
              <a href="/#about">Como funciona</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="info">
        <p>
          A 33doctor não é um plano de saúde, em nenhuma forma de interpretação, não garante e não se responsabiliza pelos serviços oferecidos pelos diversos parceiros, nem pelo pagamento das despesas, nem assegura desconto em todos os serviços garantidos por planos ou seguros de saúde. Não possui mensalidades ou anuidades.
        </p>
      </div>
    </footer>
  )
}