import { BoxFilter } from "./BoxFilter/BoxFIlter";
import "./styles.css";

// Data
import { useState } from "react";

import { Link } from "react-router-dom";

export const Filter = ({ filteredData }: any) => {
  const [search, setSearch] = useState("");

  const filtered = filteredData.filter((singleData: any) =>
    singleData.description.toLowerCase().includes(search.toLowerCase())
  );

  type ResultProps = {
    description: string;
    url: string;
    main_url: string;
    _id: number;
  };

  return (
    <div className="filter-container">
      <div>
        <div className="filter">
          <div className="filter-input">
            <input
              type="text"
              placeholder="Qual exame você quer agendar?"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>

        <div className="filter-items">
          {filtered.map((item: ResultProps, index: number) => (
            <Link to={`/${item?.main_url}/${item?.url}`} key={index}>
              <BoxFilter title={item?.description} key={index} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
